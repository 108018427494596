import { useRef, useState, useEffect } from 'react';
import { useNavigate } from '@remix-run/react';
import { Input } from './ui/input';

export const SearchAutocomplete = ({ orgs }) => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const inputRef = useRef(null);
  const navigate = useNavigate();

  const handleSearch = (e) => {
    const { value } = e.target;
    setQuery(value);
    if (value.trim()) {
      setResults(
        orgs.filter((org) => {
          const name = org.name.toLowerCase();
          const pointOfContactName = org.pointOfContactName?.toLowerCase();
          return (
            name.includes(value.toLowerCase()) ||
            pointOfContactName?.includes(value.toLowerCase())
          );
        })
      );
    } else {
      setResults([]);
    }
    setSelectedIndex(-1); // Reset selected index on new search
  };

  const handleKeyDown = (e) => {
    if (results.length > 0) {
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        setSelectedIndex((prevIndex) => (prevIndex + 1) % results.length);
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        setSelectedIndex(
          (prevIndex) => (prevIndex - 1 + results.length) % results.length
        );
      } else if (e.key === 'Enter' && selectedIndex >= 0) {
        e.preventDefault();
        handleSelectResult(results[selectedIndex]);
      }
    }
  };

  const handleSelectResult = (org) => {
    setQuery('');
    setResults([]);
    if (inputRef.current) {
      inputRef.current.blur();
    }
    navigate(`/org/${org.id}`);
  };

  useEffect(() => {
    const input = inputRef.current;
    if (input) {
      input.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      if (input) {
        input.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [results, selectedIndex]);

  return (
    <div className="max-w-lg text-black">
      <div className="relative">
        <Input
          ref={inputRef}
          type="text"
          value={query}
          onChange={handleSearch}
          placeholder="Search organizations..."
          // className="w-full  border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        {results.length > 0 && (
          <div className="absolute w-full bg-white border border-gray-300 rounded-lg shadow-lg mt-1 max-h-60 min-w-[320px] overflow-y-auto">
            {results.map((org, index) => (
              <div
                key={org.id}
                onClick={() => handleSelectResult(org)}
                className={`px-4 py-2 hover:bg-gray-100 cursor-pointer text-black ${
                  selectedIndex === index ? 'bg-gray-200' : ''
                }`}
              >
                <p className="">
                  {org.name}{' '}
                  {org.pointOfContactName && `(${org.pointOfContactName})`}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
