import { captureRemixErrorBoundaryError } from '@sentry/remix';
import { LinksFunction, redirect } from '@remix-run/node';

import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useRouteError,
} from '@remix-run/react';

import styles from './globals.css?url';
import { cssBundleHref } from '@remix-run/css-bundle';
import { Nav } from './components/nav';
import { currentUser, verifyJWT } from './utils/auth';
import { Toaster } from './components/ui/toaster';
import { get } from './utils/api';

export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: styles },
  ...(cssBundleHref ? [{ rel: 'stylesheet', href: cssBundleHref }] : []),
  { rel: 'icon', href: '/favicon.ico' },
];

export async function loader({ request }) {
  const user = currentUser(request);

  let orgs = [];

  if (user?.admin) {
    orgs = (await (await get({ request, path: 'orgs/search' })).json()).orgs;
  }

  return {
    orgs,
    user,
    isDevelopment: process.env.NODE_ENV === 'development',
  };
}

export const meta = () => [
  {
    charset: 'utf-8',
    title: `${
      process.env.NODE_ENV === 'development' ? '[DEV] ' : ''
    }Sunset | Admin`,
    viewport: 'width=device-width,initial-scale=1',
  },
];

function App() {
  const { user, isDevelopment, orgs } = useLoaderData();
  return (
    <html>
      <head>
        <link rel="icon" href="data:image/x-icon;base64,AA" />
        <Meta />
        <Links />
      </head>
      <body>
        <div className="flex min-h-screen w-full flex-col">
          <Nav user={user} isDevelopment={isDevelopment} orgs={orgs} />
          <main>
            <Outlet />
          </main>
          <ScrollRestoration />
          <Scripts />
        </div>
        <Toaster />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  if (process.env.NODE_ENV !== 'development') {
    const error = useRouteError();
    captureRemixErrorBoundaryError(error);
    return (
      <html>
        <head>
          <title>Oh no!</title>
          <Meta />
          <Links />
        </head>
        <body>
          <div className="flex h-[100dvh] flex-col items-center justify-center bg-gray-100 px-4 dark:bg-gray-900">
            <div className="mx-auto max-w-md text-center">
              <h1 className="mb-2 text-4xl font-bold tracking-tight text-gray-900 dark:text-gray-100 sm:text-5xl">
                We apologize for the inconvenience.
              </h1>
              <p className="mb-6 text-gray-600 dark:text-gray-400">
                The page you're attempting to access is currently unavailable.
                Our team has been notified and will try to resolve the issue as
                soon as possible.
              </p>
              <a
                className="inline-flex h-10 items-center justify-center rounded-md bg-gray-900 px-6 text-sm font-medium text-gray-50 shadow transition-colors hover:bg-gray-900/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 dark:bg-gray-50 dark:text-gray-900 dark:hover:bg-gray-50/90 dark:focus-visible:ring-gray-300"
                href="/"
              >
                Take me back home
              </a>
              <div className="mt-8 rounded-md bg-yellow-100 p-4 dark:bg-yellow-800">
                <p className="text-sm font-medium text-yellow-800 dark:text-yellow-200">
                  You can always reach out to your Account Manager with any
                  questions in the meantime.
                </p>
              </div>
            </div>
          </div>

          <Scripts />
        </body>
      </html>
    );
  } else {
    const error = useRouteError();

    if (isRouteErrorResponse(error)) {
      return (
        <div>
          <h1>
            {error.status} {error.statusText}
          </h1>
          <p>{error.data}</p>
        </div>
      );
    } else if (error instanceof Error) {
      return (
        <div>
          <h1>Error</h1>
          <p>{error.message}</p>
          <p>The stack trace is:</p>
          <pre>{error.stack}</pre>
        </div>
      );
    } else {
      return <h1>Unknown Error</h1>;
    }
  }
};

export default App;
